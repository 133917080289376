<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Icon</Heading>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Icon Color</Heading>
							<Paragraph>There are five color variations for icon colors and backgrounds: primary, secondary, tertiary, light and dark.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="row column@sm" justify="left" align="center left@sm">
							<Stack direction="column row@sm" space="1 2@sm">
								<Stage><Icon icon="favorite" color="primary" size="medium" /></Stage>
								<Stage><Icon icon="favorite" color="secondary" size="medium" /></Stage>
								<Stage><Icon icon="favorite" color="tertiary" size="medium" /></Stage>
								<Stage><Icon icon="favorite" color="dark" size="medium" /></Stage>
							</Stack>
							<Stack direction="column row@sm" space="1 2@sm">
								<Stage><Icon icon="favorite" background="primary" size="medium" color="light" /></Stage>
								<Stage><Icon icon="favorite" background="secondary" size="medium" color="light" /></Stage>
								<Stage><Icon icon="favorite" background="tertiary" size="medium" color="light" /></Stage>
								<Stage><Icon icon="favorite" background="dark" size="medium" color="light" /></Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Icon Sizes</Heading>
							<Paragraph>There are three size variations for icons: small, medium and large.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Stack direction="column row@sm" align="left center@sm" space="1 2@sm">
								<Icon icon="favorite" size="small" color="light" background="primary" />
								<Icon icon="favorite" size="medium" color="light" background="primary" />
								<Icon icon="favorite" size="large" color="light" background="primary" />
							</Stack>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack width direction="column" space="2" align="left">
							<Heading size="5">Icon Library</Heading>
							<Paragraph>These icons represent commonly used icons in general UI situations. These Icons are from <Link type="external" display="inline" url="https://fonts.google.com/icons">Material Design.</Link></Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Grid>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="add" color="dark" size="medium" border />
									<Paragraph>add</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="arrow_left" color="dark" size="medium" border />
									<Paragraph>arrow_left</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="arrow_right" color="dark" size="medium" border />
									<Paragraph>arrow_right</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="arrow_upward" color="dark" size="medium" border />
									<Paragraph>arrow_upward</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="auto_fix_high" color="dark" size="medium" border />
									<Paragraph>auto_fix_high</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="check" color="dark" size="medium" border />
									<Paragraph>check</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="close" color="dark" size="medium" border />
									<Paragraph>close</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="contactless" color="dark" size="medium" border />
									<Paragraph>contactless</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="content_cut" color="dark" size="medium" border />
									<Paragraph>content_cut</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="content_paste" color="dark" size="medium" border />
									<Paragraph>content_paste</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="date_range" color="dark" size="medium" border />
									<Paragraph>date_range</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="description" color="dark" size="medium" border />
									<Paragraph>description</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="email" color="dark" size="medium" border />
									<Paragraph>email</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="emoji_events" color="dark" size="medium" border />
									<Paragraph>emoji_events</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="emoji_objects" color="dark" size="medium" border />
									<Paragraph>emoji_objects</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="expand_less" color="dark" size="medium" border />
									<Paragraph>expand_less</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="expand_more" color="dark" size="medium" border />
									<Paragraph>expand_more</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="explore" color="dark" size="medium" border />
									<Paragraph>explore</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="favorite" color="dark" size="medium" border />
									<Paragraph>favorite</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="favorite_outline" color="dark" size="medium" border />
									<Paragraph>favorite_outline</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="home" color="dark" size="medium" border />
									<Paragraph>home</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="insights" color="dark" size="medium" border />
									<Paragraph>insights</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="language" color="dark" size="medium" border />
									<Paragraph>language</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="link" color="dark" size="medium" border />
									<Paragraph>link</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="mark_as_unread" color="dark" size="medium" border />
									<Paragraph>mark_as_unread</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="menu" color="dark" size="medium" border />
									<Paragraph>menu</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="menu_open" color="dark" size="medium" border />
									<Paragraph>menu_open</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="perm_phone_msg" color="dark" size="medium" border />
									<Paragraph>perm_phone_msg</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="place" color="dark" size="medium" border />
									<Paragraph>place</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="poll" color="dark" size="medium" border />
									<Paragraph>poll</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="remove" color="dark" size="medium" border />
									<Paragraph>remove</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="request_quote" color="dark" size="medium" border />
									<Paragraph>request_quote</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="room" color="dark" size="medium" border />
									<Paragraph>room</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="search" color="dark" size="medium" border />
									<Paragraph>search</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="send" color="dark" size="medium" border />
									<Paragraph>send</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="share" color="dark" size="medium" border />
									<Paragraph>share</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="shopping_bag" color="dark" size="medium" border />
									<Paragraph>shopping_bag</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="sort" color="dark" size="medium" border />
									<Paragraph>sort</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="star" color="dark" size="medium" border />
									<Paragraph>star</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="storm" color="dark" size="medium" border />
									<Paragraph>storm</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="support" color="dark" size="medium" border />
									<Paragraph>support</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="table_chart" color="dark" size="medium" border />
									<Paragraph>table_chart</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="thumb_up" color="dark" size="medium" border />
									<Paragraph>thumb_up</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="timeline" color="dark" size="medium" border />
									<Paragraph>timeline</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="track_changes" color="dark" size="medium" border />
									<Paragraph>track_changes</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="verified" color="dark" size="medium" border />
									<Paragraph>verified</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="wb_twilight" color="dark" size="medium" border />
									<Paragraph>wb_twilight</Paragraph>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@sm 3@md">
								<Stack space="2">
									<Icon icon="work" color="dark" size="medium" border />
									<Paragraph>work</Paragraph>
								</Stack>
							</GridContainer>
						</Grid>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Icon Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">icon</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">Material Icon List</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Icon to be shown inside of the icon.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary<br/>
										secondary<br/>
										tertiary<br/>
										light<br/>
										dark<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the color of the icon.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">background</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary<br/>
										secondary<br/>
										tertiary<br/>
										light<br/>
										dark<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the background color of the icon.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										small<br/>
										medium<br/>
										large
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the icon.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">border</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Applies a border to the icon.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>

</style>
